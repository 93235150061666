<template>
  <v-container>
    <v-layout
      mb-3
      wrap
    >
      <v-flex xs12>
        <v-btn
          color="primary"
          @click="$router.push({name: 'admin:new_offer'})"
        >
          <v-icon left>
            add
          </v-icon>
          Nueva Oferta
        </v-btn>
        <v-btn
          @click="showfilters = true"
        >
          <v-icon left>
            mdi-filter
          </v-icon>
          Filtros
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <v-data-table
          :headers="headers"
          :items="offers"
          :pagination.sync="pagination"
          :total-items="totalItems"
          hide-actions
          class="elevation-3"
        >
          <template v-slot:headers="props">
            <tr>
              <th
                v-for="header in props.headers"
                :key="header.text"
                align="left"
                style="font-size: small; font-weight: bold"
              >
                {{ header.text }}
              </th>
            </tr>
          </template>
          <template
            slot="items"
            slot-scope="props"
          >
            <td>{{ props.item.published_date }}</td>
            <td>{{ props.item.company }}</td>
            <td>{{ props.item.position }}</td>
            <td>{{ props.item.admin }}</td>
            <td>
              <v-btn
                :to="{name: 'admin:offer_applicants',
                      params: {uuid: props.item.uuid }}"
              >
                <v-icon left>
                  people
                </v-icon>
                {{ props.item.applicants_count }}
              </v-btn>
            </td>
            <td>
              <v-btn
                :to="{name: 'admin:offer_extapplicants',
                      params: {uuid: props.item.uuid }}"
              >
                <v-icon left>
                  work
                </v-icon>
                {{ props.item.extapplicants_count }}
              </v-btn>
            </td>
            <td>{{ props.item.state }}</td>
            <td>
              <v-tooltip
                bottom
                nudge-right="15"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    outlined
                    @click="showOfferDialog(props.item)"
                    v-on="on"
                  >
                    <v-icon>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <span>Editar oferta</span>
              </v-tooltip>
              <v-tooltip
                bottom
                nudge-right="15"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    :to="{name: 'admin:new_offer',
                          params: {offer: props.item }}"
                    icon
                    outlined
                    v-on="on"
                  >
                    <v-icon>
                      mdi-content-duplicate
                    </v-icon>
                  </v-btn>
                </template>
                <span>Duplicar oferta</span>
              </v-tooltip>
            </td>
          </template>
          <template v-slot:no-results>
            No se encontraron resultados.
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout
      v-if="offers.length > 0"
      justify-center
      mt-3
    >
      <v-flex xs4>
        <v-card>
          <v-card-text>
            <div class="text-xs-center">
              <v-pagination
                v-model="page"
                :length="pages"
                total-visible="5"
              />
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog
      v-if="edit_offer_uuid"
      id="edit-dialog"
      v-model="show_edit_dialog"
      max-width="900px"
      persistent
    >
      <EditOffer
        :key="edit_offer_uuid"
        :offer-uuid="edit_offer_uuid"
        @editExit="loadOffers()"
        @rejectConfirm="prepareReject"
      >
        <template slot="close">
          <v-btn @click="show_edit_dialog = false">
            Cancelar
          </v-btn>
        </template>
      </EditOffer>
    </v-dialog>

    <v-dialog
      id="rejectConfirm"
      v-model="rejectConfirm"
      max-width="500px"
      persistent
    >
      <v-card>
        <v-card-title>
          Rechazar oferta Marketplace
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="rejectRecommendations"
            outline
            name="input-7-4"
            label="Recomendaciones para el cliente"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="warning"
            @click="rejectOffer"
          >
            Rechazar offerta
          </v-btn>
          <v-btn
            color="warning"
            @click="resetRejection"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-navigation-drawer
      v-model="showfilters"
      right
      temporary
      fixed
    >
      <v-card
        v-if="showfilters"
        flat
      >
        <v-card-title class="headline">
          Filtros
        </v-card-title>
        <v-card-text class="justify-center">
          <v-layout wrap>
            <v-flex xs12>
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="filters.created_date"
                    label="Fecha de creación"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="filters.created_date"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="1900-01-01"
                  @change="menu2 = false"
                />
              </v-menu>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="filters.company"
                label="Empresa"
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="filters.position"
                label="Cargo"
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="filters.admin"
                label="Analista"
              />
            </v-flex>
            <v-flex xs12>
              <CountryPicker
                v-model="filters.country"
                label="País"
                :default-to-chile="false"
              />
            </v-flex>
            <v-layout
              xs12
              d-flex
              justify-left
            >
              <v-flex
                xs3
                mt-1
              >
                <v-btn
                  color="primary"
                  @click="applyfilters"
                >
                  Filtrar
                </v-btn>
                <v-btn @click="clearAndFilter">
                  Limpiar
                </v-btn>
              </v-flex>
            </v-layout>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import axios from 'axios';
import EditOffer from '@/components/offers/EditOffer.vue';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';
import CountryPicker from '@/components/inputs/CountryPicker.vue';

export default {
  name: 'OffersTableVue',
  components: {
    EditOffer,
    CountryPicker,
  },
  filters: {
    humanDate(dateStr) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateStr).toLocaleDateString('es-CL', options);
    },
  },
  mixins: [JWTConfigMixin],
  props: {
    state: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      rejectRecommendations: '',
      rejectingOffer: '',
      rejectConfirm: false,
      showfilters: false,
      pagination: {
        descending: true,
        rowsPerPage: 10,
        page: 1,
      },
      menu2: false,
      // The filters data object collects whatever it's being written on the textfields
      filters: {
        created_date: '',
        company: '',
        position: '',
        admin: '',
        applicantsCount: '',
        country: '',
      },
      stateItems: [
        { text: 'Activo', value: 'activo' },
        { text: 'Cerrado', value: 'cerrado' },
        { text: 'Suspendido', value: 'suspendido' },
      ],
      edit_offer_uuid: '',
      show_edit_dialog: false,
      dialog_title: '',
      headers: [
        {
          text: 'Fecha Creación',
          value: 'created_date',
        },
        {
          text: 'Empresa',
          value: 'company',
        },
        {
          text: 'Cargo',
          value: 'position',
        },
        {
          text: 'Analista',
          value: 'admin',
        },
        {
          text: 'Postulantes Internos',
          value: 'applicantsCount',
        },
        {
          text: 'Postulantes Externos',
          value: 'applicantsCount',
        },
        {
          text: 'Estado',
          value: 'state',
        },
      ],
      offers: [],
      page: 1,
      pages: 1,
      nextPage: '',
      prevPage: '',
      totalItems: 0,
    };
  },

  computed: {
    query_params() {
      const qarr = [];
      const qstr = `?state=${this.state}`;
      Object.keys(this.filters).forEach(val => {
        if (this.filters[val] && this.filters[val] !== '') {
          qarr.push(`${val}=${this.filters[val]}`);
        }
      });
      return `${qstr}${qarr.length > 0 ? '&' : ''}${qarr.join('&')}`;
    },
  },
  watch: {
    page(newVal, oldVal) {
      let pageEndpoint = '';
      if (Math.abs(newVal - oldVal) > 1) {
        const queryConstruct = `${this.query_params !== '' ? `${this.query_params}&` : '?'}page=${newVal}`;
        pageEndpoint = `${this.$store.state.endpoints.offers.admin.list}${queryConstruct}`;
      } else if (newVal > oldVal) {
        pageEndpoint = this.nextPage;
      } else {
        pageEndpoint = this.prevPage;
      }
      this.turnPage(pageEndpoint);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadOffers();
    });
  },
  methods: {
    prepareReject(uuid) {
      this.rejectConfirm = true;
      this.rejectingOffer = uuid;
      console.log('emitted');
    },
    rejectOffer() {
      const payload = {
        offer: this.rejectingOffer,
        recommendations: this.rejectRecommendations,
      };
      const endpoint = this.$store.state.endpoints.offers.admin.rejectOffer(this.rejectingOffer);
      axios.post(endpoint, payload, this.jwtConfig)
        .then(resp => {
          if (resp.status === 200) {
            this.resetRejection();
            this.loadOffers();
            this.$store.commit('adminSnackbarUp', 'Oferta rechazada.');
          } else {
            this.$store.commit('adminSnackbarUp', 'Error al rechazar la oferta.');
          }
        });
    },
    resetRejection() {
      this.rejectRecommendations = '';
      this.rejectConfirm = false;
    },
    clearAndFilter() {
      Object.keys(this.filters).forEach(val => {
        this.filters[val] = '';
      });
      this.applyfilters();
    },
    applyfilters() {
      this.$store.commit('setLoading');
      axios.get(`${this.$store.state.endpoints.offers.admin.list}${this.query_params}`, this.jwtConfig)
        .then(response => {
          this.nextPage = response.data.next;
          this.prevPage = response.data.previous;
          this.offers = response.data.results;
          this.totalItems = response.data.count;
          this.pages = Math.ceil(response.data.count / response.data.results.length);
          this.$store.commit('unsetLoading');
          this.page = 1;
          this.showfilters = false;
          this.setPaginator();
        });
    },
    turnPage(pageLink) {
      if (!pageLink) {
        return;
      }
      this.$store.commit('setLoading');
      axios.get(pageLink, this.jwtConfig)
        .then(response => {
          this.nextPage = response.data.next;
          this.prevPage = response.data.previous;
          this.offers = response.data.results;
          this.$store.commit('unsetLoading');
          this.setPaginator();
        });
    },
    setPaginator() {
      this.pagination.page = this.page;
      this.pagination.rowsPerPage = this.offers.length;
    },
    loadOffers() {
      this.show_edit_dialog = false;
      this.$store.commit('setLoading');
      axios.get(`${this.$store.state.endpoints.offers.admin.list}${this.query_params}`, this.jwtConfig)
        .then(response => {
          this.offers = response.data.results;
          this.nextPage = response.data.next;
          this.prevPage = response.data.previous;
          this.pages = Math.ceil(response.data.count / response.data.results.length);
          this.$store.commit('unsetLoading');
        });
    },
    showOfferDialog(offer) {
      this.edit_offer_uuid = offer.uuid;
      this.dialog_title = offer.position;
      this.show_edit_dialog = true;
    },
  },
};
</script>
