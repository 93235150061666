<template>
  <v-tabs
    color="primary"
    slider-color="secondary"
  >
    <v-tab
      key="abierto"
      style="color: white;"
    >
      Activas
    </v-tab>
    <v-tab-item key="abierto">
      <OffersTable state="activo" />
    </v-tab-item>

    <v-tab
      key="cerrado"
      style="color: white;"
    >
      Cerradas
    </v-tab>
    <v-tab-item key="cerrado">
      <OffersTable state="cerrado" />
    </v-tab-item>

    <v-tab
      key="suspendido"
      style="color: white;"
    >
      Suspendidas
    </v-tab>
    <v-tab-item key="suspendido">
      <OffersTable state="suspendido" />
    </v-tab-item>

    <v-tab
      key="pendiente"
      style="color: white;"
    >
      Pendientes
    </v-tab>
    <v-tab-item key="pendiente">
      <OffersTable state="pendiente" />
    </v-tab-item>

    <v-tab
      key="rechazada"
      style="color: white;"
    >
      Rechazadas
    </v-tab>
    <v-tab-item key="rechazada">
      <OffersTable state="rechazado" />
    </v-tab-item>
  </v-tabs>
</template>

<script>
import OffersTable from '@/components/offers/OffersTable.vue';

export default {
  name: 'Offers',
  components: { OffersTable },
};
</script>
